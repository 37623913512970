/** @jsx jsx */
import { Box, Grid, jsx } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";
import banner from "../../static/imgs/heartfulness-green-donation-banner.jpg";

const GeneralDonationSRCM = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Donation SRCM, USA";
    return `<gatsby_donation donationId = ${id} btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "General Donation SRCM, USA" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Heartfulness_Donation_SRCM,_USA", event),
    []
  );

  return (
    <>
      <DonationLayout
        seoData={{
          title: "Heartfulness Donations",
        }}
      >
        <Box
          sx={{
            paddingTop: "30px",
            alignItems: "center",
            minHeight: "620px",
            maxHeight: "620px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${banner})`,
          }}
        >
          <Box sx={{ paddingBottom: "30px" }}>
            <h2
              sx={{
                color: "White",
                marginTop: "20px",
                fontWeight: "500",
                lineHeight: "1.2",
                fontSize: "55px",
                marginBottom: "1rem",
                textAlign: "center!important",
              }}
            >
              SRCM USA Donations
            </h2>
            <PageContent
              eventCallback={eventCallback}
              pageContent={pageContent(15, "Contribute Now")}
            />
          </Box>
        </Box>
        <Grid>
          <Box>
            <div className="container" sx={{ padding: "30px 20px" }}>
              <div>
                <h1
                  sx={{
                    fontSize: "1.5em",
                    color: "#2d3550",
                    fontWeight: "600",
                  }}
                >
                  General Donation to SRCM, USA
                </h1>
                <p sx={{ marginBottom: "1rem", textAlign: "justify" }}>
                  All donations to Shri Ram Chandra Mission are voluntary. Shri
                  Ram Chandra Mission is registered as a tax-exempt organization
                  under section 501-C3 of the Internal Revenue Code. Donated
                  funds are used for:
                </p>
                <ul>
                  <li sx={{ listStyleType: "disc", marginBottom: "0.5rem" }}>
                    Maintenance and support of existing ashrams and meditation
                    centers.
                  </li>
                  <li sx={{ listStyleType: "disc", marginBottom: "0.5rem" }}>
                    Hosting of seminars, training courses, and other activities.
                  </li>
                  <li sx={{ listStyleType: "disc", marginBottom: "0.5rem" }}>
                    Printing and distribution of publications and other
                    multimedia content.
                  </li>
                  <li sx={{ listStyleType: "disc", marginBottom: "0.5rem" }}>
                    Support research, education and training courses to promote
                    Sahaj Marg Heartfulness meditation and practice.
                  </li>
                </ul>
              </div>
              <div className="col-12 text-center">
                <PageContent
                  eventCallback={eventCallback}
                  pageContent={pageContent(15, "Contribute Now")}
                />
              </div>
            </div>
          </Box>
        </Grid>
      </DonationLayout>
    </>
  );
};

export default GeneralDonationSRCM;
